body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body{
  height: auto;
  overflow-y: scroll;
}
body::-webkit-scrollbar-track
{

	border-radius: 10px;
	background-color:transparent;
}
body::-webkit-scrollbar
{
	width: 7px;
	background-color: transparent;
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb
{
	border-radius: 10px;

	background-color: rgb(4,89,94);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.nav{
    width: 100%;
    background: rgb(4,89,94);
    color: #fff;
    justify-content: center;
}
.nav h2{
    font-family: 'Tapestry', cursive;
    font-size: 50px;
    padding: 30px 0px;
    font-weight: 900;
    color: #fff;
}
.nav ul {
    margin-top: 45px;
}
.nav ul li{
    list-style: none;
    display: inline;
    padding:0px 50px;
    font-size: 20px;
    font-weight: 550;
}
.nav ul li a{
    color: #fff;
}
.selected-header-option span {
    border-bottom: 3px solid #fff;
}
.nav ul li span:hover{
    border-bottom: 3px solid #fff;
}
.hero-section{
    height: auto;
    width: 100%;
    background: rgb(4,89,94);  
    position: relative;  
}
.contains{
    display: flex;
}
.hero-footer img{
position: absolute;
bottom:0;
}
.social-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.social-icons .icon{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #fff;
position: relative;
margin: 5px;
}
.social-icons .icon i{
    position: absolute;
    left: 8px;
    top: 8px;
    color: rgb(4,89,94);
}
.social-icons .icon .fa-facebook-f{
    padding-left: 2px;
}
.hero-section .name{

    color: #fff;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.hero-section .hello{
    color: #fff;
}
.hero-section .name span{
    color: rgb(149, 255, 0);
}
.hero-section .typicals{
    color: #fff;
    font-size: 35px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.hero-section .my-position .parg{
text-align: center;
font-size: 18px;
color: #fff;
font-weight: 550;
}
.hero-section .buttons{
    text-align: center;
}
.hero-section .buttons button{
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 7px 10px;
    margin: 0px 20px;
}
.hero-section .buttons .resume{
    background-color: rgb(141, 19, 19);
    padding: 9px 10px;
    border: none;
}
.hero-section .my-image{
    height: 300px;
    width: 300px;
    border-radius: 50%;
    text-align: right;
    border: 2px solid #fff;

}

.hero-section .my-image img{
    height: 285px;
    width: 285px;
    border-radius: 50%;
    margin-top: 6px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}
.hero-section .my-image img:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

/* drawer */

.drawer-icon .fa-bars{
font-size: 28px;
margin-top: 45px;
margin-left: 90px;
display: none;
}
.drawers li{
    list-style: none;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 550;
    padding: 40px;
}
.drawers li a{
    color: #fff;
}
.ant-drawer-content {
    background-color: rgb(4,89,94);
    padding-bottom: 10px;
}
.ant-drawer-header{
    background-color: rgb(4,89,94);
}
.ant-drawer-close{
    position: absolute;
    right: 0;
    font-size: 30px;
    color: #fff;
    top: 20px;
}

@media screen and (max-width: 1100px) {
    .nav ul li{
        display: none;
    }
    .drawer-icon .fa-bars{
        display: block;
    }
    .contains{
        display: flex;
        flex-wrap: wrap-reverse;
    }
    .social-icons{
        margin-top: -70px;
    }
    .nav h2{
        font-size: 40px;
        padding: 30px 0px;
        font-weight: 900;
        margin-left: 8px;
    }
    .hero-section .my-image{
        margin-top: -10px;
    }

}
@media screen and (max-width: 900px){
    .hero-section .my-image{
        margin-top: -50px;
    }
    
}

.about-me .about-title h2{
text-align: center;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.about::after{
    content: "";
    display: block; 
    margin: 0 auto; 
    width: 6%; 
    padding-top: 7px; 
    border-bottom: 4px solid rgb(4,89,94); 
}
.about-me .about-content{
    display: flex;
}
.about-me .about-content p{
    text-align: justify;
}
.about-me .about-content img{
    border: 5px solid rgb(4,89,94);
}
.about-me .about-paragraph{
    font-size: 18px;
    font-weight: 550;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.about-me .buttons button{
    border: none;
    background-color: rgb(4,89,94);
    color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 7px 10px;
    margin: 0px 20px;
}
.about-me .buttons .resume{
    background-color: rgb(141, 19, 19);
    border: none;
}

/* resume */

.my-resume .resume-title h2{
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.my-resume .resume-title .resume::after{
    content: "";
    display: block; 
    margin: 0 auto; 
    width: 4%; 
    padding-top: 7px; 
    border-bottom: 4px solid rgb(4,89,94); 
}
.ant-tabs-nav-list{
    width: 300px;
    
}
.resume-content .ant-tabs-nav{
    border-right: none;
    padding: 20px 0px;
}
.resume-content .ant-tabs-left{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.resume-content .ant-tabs-tab-active{
    background-color:rgb(4,89,94) ;
    font-size: 22px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.resume-content .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #fff;
}
.resume-content .ant-tabs-tab{
    margin:20px 0px;
    font-size: 22px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.resume-content .university{
    display: flex;
    justify-content: space-between;
}
.resume-content .university li,.resume-content .parags{
    font-weight: bold;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
.resume-content .university li{
    color: rgb(141, 19, 19);
}
.resume-content .university button{
    color: #fff;
    border-radius: 10px;
    font-size: 18px;
    background-color: rgb(141, 19, 19);
    padding: 5px 10px;
    border: none;
}
.resume-content .exucation{
    padding: 50px;
}
.resume-content .progress{
    width: 150px;
    margin-left: 30px;
    margin-top: -15px;
    height: 10px;
}
.resume-content .progress-bar{
    background-color: rgb(141, 19, 19);
    height: 10px;
}
.resume-content .skill-flex{
    display: flex;
    flex-wrap: wrap;
}
.resume-content .education a{
    color: green;
    padding-left: 40px;
    font-size: 17px;
}
.resume-content .slink{
    margin-top: -15px;
}
.resume-content .technology{
    font-weight: 550;
    font-size: 17px;
    margin-top: -15px;
    font-family: Arial, Helvetica, sans-serif;
}
.resume-content .projects{
    overflow-y: scroll;
    height: 380px;
}
.resume-content .projects::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.resume-content .projects::-webkit-scrollbar
{
	width: 9px;
	background-color: #F5F5F5;
    border-radius: 10px;
}
.resume-content .projects::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(141, 19, 19);
}

/* contact */

.contact-me{
    height: auto;
    width: 100%;
    /* background:url('../../public/images/laps.jpg'); */
    background-color:rgb(4,89,94) ;
    background-size: cover;
    position: relative;
}
.contact-me .contact-title h2{
    color: #fff;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.contact-me .contact-title .contact::after{
    content: "";
    display: block; 
    margin: 0 auto; 
    width: 7%; 
    padding-top: 7px; 
    border-bottom: 4px solid rgb(141, 19, 19); 
}
.contact-me .footimage{
    position: absolute;
    bottom: 0;
}
.contact-me .get-touch h2{
    color: #fff;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.contact-me .touch{
    text-align: center;
}
.contact-me .social-iconss{
    color: #fff;
    font-size: 18px;
}
.contact-me .social-iconss i{
    margin: 0px 10px;
}
.contact-me .email p{
    color: #fff;
    font-size: 18px;
}

.contactForm{
    width: 90%;
    padding: 20px 40px 5px 40px;
    background: #fff;
    text-align: center;
}
.contactForm h2{
    font-size: 30px;
    color: #333;
    font-weight: 500;
}
.contactForm .inputBox{
    position: relative;
    width: 100%;
    margin-top: 10px;
}
.contactForm .inputBox input,.contactForm .inputBox textarea{
    width: 100%;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    border: none;
    border-bottom: 2px solid #333;
    outline: none;
    resize: none;
}
.contactForm .inputBox span{
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;
}
.contactForm .inputBox input:focus~span,
.contactForm .inputBox input:valid~span,
.contactForm .inputBox textarea:focus~span,
.contactForm .inputBox textarea:valid~span{
    color:black;
    font-size: 12px;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
}
.contactForm .inputBox input[type="submit"]{
    width: 100px;
    background: rgb(4,89,94);
    color: #fff;
    border:none;
    cursor: pointer;
    padding: 5px;
    font-size: 18px;
}

/* footer */

.footer{
    text-align: center;
}
.footer p{
    font-size: 17px;
}
@media screen and (max-width: 1100px){
    .about-me .about-content{
        flex-wrap: wrap-reverse;
    }   
    .ant-tabs{
        flex-wrap: wrap;
    }
}
.fa-arrow-circle-up{
    position:fixed; 
    width: 100%;
    bottom: 60px;
    right: -95%;
    height: 20px;
    font-size: 35px;
    z-index: 1;
    cursor: pointer;
    color: rgb(141, 19, 19);
}

@media screen and (max-width: 900px){
    .btns{
        display: none;
    }
    
}

.parallax {
    /* The image used */
    background-image: url(/static/media/parallel.d009f9a7.jpg);
    min-height: 200px; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
.testi-head {
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.testi-head h2::after{
    content: "";
    display: block; 
    margin: 0 auto; 
    width: 5%; 
    padding-top: 7px; 
    border-bottom: 4px solid rgb(141, 19, 19); 
}
.testi{
    background-color: rgb(90, 156, 159);
    border-bottom: 2px solid #fff;
}

.testimo{
    width: 90%;
    margin: auto;
    padding-bottom: 30px;
}
.testimo .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #ccc;
}
.testimo .slick-dots li button{
    color:#fff;
}
.testi .slick-dots li button:before{
    color: #fff;
}
.testi .inner-card{
    margin: 30px;
}
.testi .quotes{
    font-size: 25px;
    margin: 0px 10px;
    color: rgb(141, 19, 19);
}
.testi .cds-footer-img{
    margin-top: 20px;
    margin-left: 20px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
}
.testi .cds-footer-text{
    margin-left: 15px;
    margin-top: 35px;
}
.testi .cds-footer-text h6{
    color: #fff;  
}
.testi .cds-footer-text p{
    color: #22aad3;
    font-style: italic;
}
.testi .cds-footer-img img{
    height: 80px;
    width: 80px;
    border-radius: 50%;
}
.testi .cards p{
    color: #fff;
    text-align: justify;
}
.testi .cards {
    position: relative;
    padding: 40px 50px;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-color: #e5e7e9;
}
.testi .cards:before {
    left: 10px;
    width: 46px;
    border-width: 1px 1px 0 0;
    -webkit-transform: skew(45deg);
    transform: skew(45deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    content: '';
    position: absolute;
    bottom: -10px;
    height: 10px;
    border-style: solid;
    border-color: #e5e7e9;
    background-color: transparent;
}
.testi .cards:after {
    right: 10px;
    width: calc(100% - 66px);
    border-width: 1px 0 0 1px;
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
}
.testi .cards:after{
    content: '';
    position: absolute;
    bottom: -10px;
    height: 10px;
    border-style: solid;
    border-color: #e5e7e9;
    background-color: transparent;
}
@media (max-width: 924px) {
    .testi  .testimo{
        width: 100%;
    }
}


