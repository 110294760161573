.fa-arrow-circle-up{
    position:fixed; 
    width: 100%;
    bottom: 60px;
    right: -95%;
    height: 20px;
    font-size: 35px;
    z-index: 1;
    cursor: pointer;
    color: rgb(141, 19, 19);
}

@media screen and (max-width: 900px){
    .btns{
        display: none;
    }
    
}