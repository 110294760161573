
.parallax {
    /* The image used */
    background-image: url("../../img/parallel.jpg");
    min-height: 200px; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
.testi-head {
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.testi-head h2::after{
    content: "";
    display: block; 
    margin: 0 auto; 
    width: 5%; 
    padding-top: 7px; 
    border-bottom: 4px solid rgb(141, 19, 19); 
}
.testi{
    background-color: rgb(90, 156, 159);
    border-bottom: 2px solid #fff;
}

.testimo{
    width: 90%;
    margin: auto;
    padding-bottom: 30px;
}
.testimo .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #ccc;
}
.testimo .slick-dots li button{
    color:#fff;
}
.testi .slick-dots li button:before{
    color: #fff;
}
.testi .inner-card{
    margin: 30px;
}
.testi .quotes{
    font-size: 25px;
    margin: 0px 10px;
    color: rgb(141, 19, 19);
}
.testi .cds-footer-img{
    margin-top: 20px;
    margin-left: 20px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
}
.testi .cds-footer-text{
    margin-left: 15px;
    margin-top: 35px;
}
.testi .cds-footer-text h6{
    color: #fff;  
}
.testi .cds-footer-text p{
    color: #22aad3;
    font-style: italic;
}
.testi .cds-footer-img img{
    height: 80px;
    width: 80px;
    border-radius: 50%;
}
.testi .cards p{
    color: #fff;
    text-align: justify;
}
.testi .cards {
    position: relative;
    padding: 40px 50px;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-color: #e5e7e9;
}
.testi .cards:before {
    left: 10px;
    width: 46px;
    border-width: 1px 1px 0 0;
    -webkit-transform: skew(45deg);
    -ms-transform: skew(45deg);
    transform: skew(45deg);
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    content: '';
    position: absolute;
    bottom: -10px;
    height: 10px;
    border-style: solid;
    border-color: #e5e7e9;
    background-color: transparent;
}
.testi .cards:after {
    right: 10px;
    width: calc(100% - 66px);
    border-width: 1px 0 0 1px;
    -webkit-transform: skew(-45deg);
    -ms-transform: skew(-45deg);
    transform: skew(-45deg);
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
}
.testi .cards:after{
    content: '';
    position: absolute;
    bottom: -10px;
    height: 10px;
    border-style: solid;
    border-color: #e5e7e9;
    background-color: transparent;
}
@media (max-width: 924px) {
    .testi  .testimo{
        width: 100%;
    }
}

